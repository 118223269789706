@import '@primer/css/dist/color-modes.css';

.react-calendar {
    border: none;
    background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: var(--color-canvas-overlay);
}
.react-calendar__navigation button:disabled {
    cursor: not-allowed;
    background: none;
    /*color: var(--color-neutral-subtle);*/
    color: #8D94A0;
}
.react-calendar__navigation__arrow {
    color: var(--color-fg-default);
}
.react-calendar__navigation__label {
    color: var(--color-fg-default);
}
.react-calendar__tile {
    color: var(--color-fg-default);
    border-radius: 6px;
}
.react-calendar__tile--now {
    background: var(--color-success-muted);
}
.react-calendar__month-view__days__day--weekend {
    color: var(--color-danger-fg);
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--color-fg-subtle);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: var(--color-accent-emphasis);
    color: var(--color-fg-on-emphasis);
}
.react-calendar__tile:disabled {
    cursor: not-allowed;
    background: none;
    /*color: var(--color-neutral-subtle);*/
    color: #8D94A0;
}
