@keyframes fade_in {
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade_out {
  to {
    opacity: 0;
  }
}

.toast_in {
  animation: fade_in 500ms ease-in both;
}

.toast_out {
  animation: fade_out 300ms ease-in both;
}
